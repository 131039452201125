<template>
    <a-tabs v-model:activeKey="activeKey" @change="updateUrl">
    <a-tab-pane key="TO_BE_VISITED" tab="待回访" >
         <table-page :config="config" />
    </a-tab-pane>
    <a-tab-pane key="RETURNED_VISITED" tab="已回访" >
        <table-page :config="config" />
    </a-tab-pane>
    <a-tab-pane key="CANCELED" tab="已取消">
        <table-page :config="config" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import {intentionLevel} from '@/utils/public';
export default {
    name: 'SaasAdminVisitCustomerDetail',
    components: { TablePage },
    props: ['selectedRows'],
    data() {
        return {
            activeKey:'TO_BE_VISITED',
            config: {},
            rowActions:[
                {
                    key: 'detail',
                    text: '详情',
                    type: 'blank',
                    permission: '/retention/customerReturnVisits/details',
                    config: {
                    title: '客户详情',
                    component: () => import("./vistitDetail/visitDetail")
                    }
                }
            ]
        };
    },
    created() {
       //默认
        this.config={
                checkbox: true,
                dataUrl: `/retention/customerReturnVisits/list?statusIs=TO_BE_VISITED`,
                 filter: {
                    initUrl: '/retention/customerReturnVisits/list/get',
                    controls: [
                        {
                            key: 'nameContains',
                            label: '客户姓名',
                            type: 'text'
                        },
                        {
                            key: 'phoneNumberContains',
                            label: '手机号',
                            type: 'text'
                        },
                        {
                            key: 'wechatContains',
                            label: '微信号',
                            type: 'text'
                        },
                        {
                            key: 'intentionLevelIs',
                            label: '意向等级',
                            type: 'select',
                            config: {
                                options: intentionLevel
                            }
                        },
                        {
                            key: 'attributablePersonIdIs',
                            label: '客资归属人',
                            type: 'souSelect',
                            width: '260',
                            config: {
                                options: "attributablePersonList"
                            }
                        },
                    ]
                },
                columns: [
                    {
                        key: 'institutionsCustomer.name',
                        title: '客户姓名',
                         width: '100px',
                    },
                    {
                        key: 'attributablePerson.name',
                        title: '客资归属人',
                         width: '100px',
                    },
                    {
                        key: 'institutionsCustomer.intentionLevel',
                        title: '意向等级',
                        type: 'enum',
                        width: '80px',
                        align: 'center',
                        config: {
                            constants: {
                                HIGH: {
                                text: '高',
                                color: 'orange'
                                },
                                MEDIUM: {
                                text: '中',
                                color: 'green'
                                },
                                LOW: {
                                text: '低',
                                color: 'red'
                                },
                                INVALID: {
                                text: '无效',
                                color: 'grey'
                                }
                            }
                       }
                    },
                    {
                        key: 'returnToTheTopic',
                        title: '回访主题',
                        width: '200px',
                        ellipsis: true
                    },
                    {
                        key: 'estimatedReturnVisitTime',
                        title: '计划回访时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'name',
                        title: '操作',
                        type: 'actions',
                        align: 'center',
                        fixed: 'right',
                        width: document.documentElement.clientWidth <= 768 ? '70px' : '150px'
                    }
                ],
                /* 表格操作 */
                actions: [
                     {
                        key: 'edit',
                        text: '取消回访',
                        icon: 'plus',
                        type: 'form',
                        isSelected: true,  // 是否必勾选
                        permission: '/retention/customerReturnVisits/cancel',
                        config: {
                            title: '取消回访',
                            submitUrl: '/retention/customerReturnVisits/cancel',
                            submitText: '确认',
                            info: (rows) => {
                                return `您共选择 ${rows.length} 条回访计划,是否全部取消`
                            },
                            controls: [
                                {
                                    key: 'outcome',
                                    label: '取消原因',
                                    type: 'textarea',
                                    required: true,
                                    config: {
                                        rules: [
                                        { min: 1, max: 200, message: '只能输入1-200个字符' }
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                ],
                /* 表格排序 */
                sorter: {
                /* 可排序列 */
                sortableColumns: ['estimatedReturnVisitTime'],
                /* 默认排序 */
                defaultSort: 'estimatedReturnVisitTime'
                },

                /* 表格行操作 */
                rowActions: [
                    {
                        key: 'detail',
                        text: '详情',
                        type: 'blank',
                        permission: '/retention/customerReturnVisits/details',
                        config: {
                        title: '客户详情',
                        component: () => import("./vistitDetail/visitDetail")
                        }
                    },
                    {
                        key: 'detail',
                        text: '执行回访',
                        type: 'blank',
                        permission: '/retention/customerReturnVisits/returnVisit',
                        config: {
                        title: '执行回访',
                        component: () => import("./vistitDetail/toVisit")
                        }
                    },
                ]
            }
        },
    methods: {
       updateUrl(activeKey){
         this.config.dataUrl=`/retention/customerReturnVisits/list?statusIs=${activeKey}`
         //已回访
         if(activeKey=='RETURNED_VISITED'){
           this.config.columns=[
                    {
                        key: 'institutionsCustomer.name',
                        title: '客户姓名',
                         width: '100px',
                    },
                    {
                        key: 'attributablePerson.name',
                        title: '客资归属人',
                         width: '100px',
                    },
                    {
                        key: 'institutionsCustomer.intentionLevel',
                        title: '意向等级',
                        type: 'enum',
                        width: '80px',
                        align: 'center',
                        config: {
                            constants: {
                                HIGH: {
                                text: '高',
                                color: 'orange'
                                },
                                MEDIUM: {
                                text: '中',
                                color: 'green'
                                },
                                LOW: {
                                text: '低',
                                color: 'red'
                                },
                                INVALID: {
                                text: '无效',
                                color: 'grey'
                                }
                            }
                       }
                    },
                    {
                        key: 'returnToTheTopic',
                        title: '回访主题',
                        width: '200px',
                        ellipsis: true
                    },
                    {
                        key: 'estimatedReturnVisitTime',
                        title: '计划回访时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'outcome',
                        title: '回访结果',
                        width: '200px',
                        ellipsis: true
                    },{
                        key: 'operator.username',
                        title: '操作人',
                        width: '200px',
                        ellipsis: true
                    },{
                        key: 'returnVisitTime',
                        title: '回访时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'name',
                        title: '操作',
                        type: 'actions',
                        align: 'center',
                        fixed: 'right',
                        width: document.documentElement.clientWidth <= 768 ? '50px' : '150px'
                    }
                ],
                this.config.actions=[]
                this.config.rowActions=this.rowActions
                 this.config.sorter={
                    sortableColumns: ['estimatedReturnVisitTime'],
                    defaultSort: '-estimatedReturnVisitTime'
                }
            }else if(activeKey=='CANCELED'){
               this.config.columns= [
                    {
                        key: 'institutionsCustomer.name',
                        title: '客户姓名',
                         width: '100px',
                    },
                    {
                        key: 'attributablePerson.name',
                        title: '客资归属人',
                         width: '100px',
                    },
                    {
                        key: 'institutionsCustomer.intentionLevel',
                        title: '意向等级',
                        type: 'enum',
                        width: '80px',
                        align: 'center',
                        config: {
                            constants: {
                                HIGH: {
                                text: '高',
                                color: 'orange'
                                },
                                MEDIUM: {
                                text: '中',
                                color: 'green'
                                },
                                LOW: {
                                text: '低',
                                color: 'red'
                                },
                                INVALID: {
                                text: '无效',
                                color: 'grey'
                                }
                            }
                       }
                    },
                    {
                        key: 'returnToTheTopic',
                        title: '回访主题',
                        width: '200px',
                        ellipsis: true
                    },
                    {
                        key: 'estimatedReturnVisitTime',
                        title: '计划回访时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'outcome',
                        title: '取消原因',
                        width: '200px',
                        ellipsis: true
                    },
                    {
                        key: 'operator.username',
                        title: '操作人',
                        width: '200px',
                        ellipsis: true
                    },
                    {
                        key: 'returnVisitTime',
                        title: '取消时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'name',
                        title: '操作',
                        type: 'actions',
                        align: 'center',
                        fixed: 'right',
                        width: document.documentElement.clientWidth <= 768 ? '50px' : '150px'
                    }
                ],
                this.config.actions=[]
                this.config.rowActions=this.rowActions
                this.config.sorter={
                    sortableColumns: ['returnVisitTime'],
                    defaultSort: '-returnVisitTime'
                }
            }else{
                //默认
                this.config= {
                checkbox: true,
                dataUrl: `/retention/customerReturnVisits/list?statusIs=TO_BE_VISITED`,
                 filter: {
                    initUrl: '/retention/customerReturnVisits/list/get',
                    controls: [
                        {
                            key: 'nameContains',
                            label: '客户姓名',
                            type: 'text'
                        },
                        {
                            key: 'phoneNumberContains',
                            label: '手机号',
                            type: 'text'
                        },
                        {
                            key: 'wechatContains',
                            label: '微信号',
                            type: 'text'
                        },
                        {
                            key: 'intentionLevelIs',
                            label: '意向等级',
                            type: 'select',
                            config: {
                                options: intentionLevel
                            }
                        },
                        {
                            key: 'attributablePersonIdIs',
                            label: '客资归属人',
                            type: 'souSelect',
                            width: '260',
                            config: {
                                options: "attributablePersonList"
                            }
                        },
                    ]
                },
                columns: [
                    {
                        key: 'institutionsCustomer.name',
                        title: '客户姓名',
                         width: '100px',
                    },
                    {
                        key: 'attributablePerson.name',
                        title: '客资归属人',
                         width: '100px',
                    },
                    {
                        key: 'institutionsCustomer.intentionLevel',
                        title: '意向等级',
                        type: 'enum',
                        width: '80px',
                        align: 'center',
                        config: {
                            constants: {
                                HIGH: {
                                text: '高',
                                color: 'orange'
                                },
                                MEDIUM: {
                                text: '中',
                                color: 'green'
                                },
                                LOW: {
                                text: '低',
                                color: 'red'
                                },
                                INVALID: {
                                text: '无效',
                                color: 'grey'
                                }
                            }
                       }
                    },
                    {
                        key: 'returnToTheTopic',
                        title: '回访主题',
                        width: '200px',
                        ellipsis: true
                    },
                    {
                        key: 'estimatedReturnVisitTime',
                        title: '计划回访时间',
                        width: '130px',
                        type: 'datetime'
                    },
                    {
                        key: 'name',
                        title: '操作',
                        type: 'actions',
                        align: 'center',
                        fixed: 'right',
                        width: 150
                    }
                ],
                /* 表格操作 */
                actions: [
                     {
                        key: 'edit',
                        text: '取消回访',
                        icon: 'plus',
                        type: 'form',
                        isSelected: true,  // 是否必勾选
                        permission: '/retention/customerReturnVisits/cancel',
                        config: {
                            title: '取消回访',
                            submitUrl: '/retention/customerReturnVisits/cancel',
                            submitText: '确认',
                            info: (rows) => {
                                return `您共选择 ${rows.length} 条回访计划,是否全部取消`
                            },
                            controls: [
                                {
                                    key: 'outcome',
                                    label: '取消原因',
                                    type: 'textarea',
                                    required: true,
                                    config: {
                                        rules: [
                                        { min: 1, max: 200, message: '只能输入1-200个字符' }
                                        ]
                                    }
                                },
                            ]
                        }
                    },
                ],
                /* 表格排序 */
                sorter: {
                /* 可排序列 */
                sortableColumns: ['estimatedReturnVisitTime'],
                /* 默认排序 */
                defaultSort: 'estimatedReturnVisitTime'
                },

                /* 表格行操作 */
                rowActions: [
                    {
                        key: 'detail',
                        text: '详情',
                        type: 'blank',
                        permission: '/retention/customerReturnVisits/details',
                        config: {
                        title: '客户详情',
                        component: () => import("./vistitDetail/visitDetail")
                        }
                    },
                    {
                        key: 'detail',
                        text: '执行回访',
                        type: 'blank',
                        permission: '/retention/customerReturnVisits/returnVisit',
                        config: {
                        title: '执行回访',
                        component: () => import("./vistitDetail/toVisit")
                        }
                    },
                ]
            }
            }
       } 
    },
};
</script>

<style lang="less" scoped>

</style>